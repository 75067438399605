<template>
  <div>
    <v-btn color="success" small dark @click.stop="dialog = true">
      {{ $t('MENU.STAFF.DRIVERS') }} +
    </v-btn>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="headline">Haydovchi qoshish</v-card-title>
        <v-card-text>
          <v-text-field
            label="Username"
            v-model="username"
            dense
            outlined
          ></v-text-field>

          <v-text-field
            label="Parol"
            type="password"
            v-model="password"
            dense
            outlined
          ></v-text-field>
          <v-autocomplete
            v-model="driverSelect"
            :items="getNonEmployedList"
            label="Personal ma'lumot"
            item-text="full_name"
            outlined
            item-value="id"
            dense
          ></v-autocomplete>
          <v-dialog
            ref="dialog3"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="date | dateFilter"
                label="Ishga olingan sana"
                append-icon="event"
                readonly
                v-bind="attrs"
                outlined
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Ypoish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog3.save(date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <v-autocomplete
            v-model="staffPosition"
            :items="getPositionStaff"
            label="Hodim lavozimi"
            item-text="name"
            item-value="id"
            outlined
            dense
          ></v-autocomplete>
          <v-text-field
            label="Izoh"
            v-model="comments"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :disabled="newDriverLoading"
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>

          <v-btn color="success" :disabled="newDriverLoading" @click="submit"
            ><i v-if="newDriverLoading" class="el-icon-loading"></i> Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newDriverLoading: false,
      oblastSelect: '',
      dialog: false,
      staffPosition: '',
      driverSelect: '',
      username: '',
      password: '',
      comments: '',
      staffpersonal: '',
      position: '',
      date: '',
      modal: false
    }
  },
  created() {
    this.$store.dispatch('staffPositions')
    this.$store.dispatch('getAllNonEmployedStaff')
    this.$store.dispatch('getAllGroups')
  },
  computed: {
    getPositionStaff() {
      return this.$store.getters.staffPositions.find((obj) => obj.id == 3)
    },
    getNonEmployedList() {
      return this.$store.getters.allNonEmployedStaff
    },
    allGroups() {
      return this.$store.state.requests.allGroups.find(
        (obj) => obj.name == 'driver'
      )
    }
  },
  watch: {
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    }
  },
  methods: {
    submit() {
      const data = {
        staffpersonal: this.driverSelect,
        position: this.staffPosition,
        comments: this.comments,
        employed_from: this.date,
        user_data: {
          username: this.username,
          password: this.password,
          groups: this.allGroups.id
        }
      }
      console.log(data)
      this.newDriverLoading = true
      this.$store
        .dispatch('createAllStaff', data)
        .then(() => {
          this.newDriverLoading = false
          this.username = ''
          this.staffPosition = ''
          this.driverSelect = ''
          this.employed_from = ''
          this.password = ''
          this.comments = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newDriverLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
